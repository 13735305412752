.card-events-list {
	z-index: 0;
	width: 100%;
	display: flex;
	justify-content: space-around;
	flex-wrap: wrap;
}
.card-events-list a {
	display: block;
}

.card-events {
	margin: 60px auto;
	width: 300px;
	height: 300px;
	border-radius: 40px;
	box-shadow: 5px 5px 30px 7px rgba(0, 0, 0, 0.25),
		-5px -5px 30px 7px rgba(0, 0, 0, 0.22);
	cursor: pointer;
	transition: 0.4s;
}
.flex-base-third {
	flex-basis: 33%;
}

.card-events .card-events_image {
	width: inherit;
	height: inherit;
	border-radius: 40px;
	box-shadow: 0 0 0 5px violet, 0 0 0 10px indigo, 0 0 0 15px blue,
		0 0 0 20px green, 0 0 0 25px yellow, 0 0 0 30px orange, 0 0 0 35px red;
}

.card-events .card-events_image img {
	width: inherit;
	height: inherit;
	border-radius: 40px;
	object-fit: cover;
}

.card-events .card-events_title {
	text-align: center;
	border-radius: 0px 0px 40px 40px;
	font-family: sans-serif;
	font-weight: bold;
	font-size: 30px;
	margin-top: -80px;
	height: 40px;
}

.card-events:hover {
	transform: scale(0.9, 0.9);
	box-shadow: 5px 5px 30px 15px rgba(0, 0, 0, 0.25),
		-5px -5px 30px 15px rgba(0, 0, 0, 0.22);
}

.title-white {
	color: white;
}

.title-black {
	color: black;
}

@media all and (max-width: 500px) {
	.card-events-list {
		/* On small screens, we are no longer using row direction but column */
		flex-direction: column;
	}
}

/*
.card-events {
  margin: 30px auto;
  width: 300px;
  height: 300px;
  border-radius: 40px;
  background-image: url('https://i.redd.it/b3esnz5ra34y.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-repeat: no-repeat;
box-shadow: 5px 5px 30px 7px rgba(0,0,0,0.25), -5px -5px 30px 7px rgba(0,0,0,0.22);
  transition: 0.4s;
}
*/

.a-box {
	display: inline-block;
	width: 340px;
	text-align: center;
	margin: 13px 30px;
	padding: 20px;
}

.img-container {
	height: 230px;
	width: 200px;
	overflow: hidden;
	border-radius: 0px 0px 20px 20px;
	display: inline-block;
}

.img-container img {
	transform: skew(0deg, -13deg);
	height: 250px;
	margin: -35px 0px 0px -70px;
}

.inner-skew {
	display: inline-block;
	border-radius: 20px;
	overflow: hidden;
	padding: 0px;
	transform: skew(0deg, 13deg);
	font-size: 0px;
	margin: 30px 0px 0px 0px;
	background: #c8c2c2;
	height: 250px;
	width: 200px;
}

.text-container {
	box-shadow: 0 0 0 5px violet, 0 0 0 10px indigo, 0 0 0 15px blue,
		0 0 0 20px green, 0 0 0 25px yellow, 0 0 0 30px orange, 0 0 0 35px red;
	padding: 120px 20px 20px 20px;
	border-radius: 20px;
	background: #fff;
	margin: -120px 0px 0px 0px;
	line-height: 19px;
	font-size: 14px;
}

.text-container h3 {
	margin: 20px 0px 10px 0px;
	color: black;
	font-size: 18px;
}

.step-wrap {
	display: flex;
	gap: 20px;
	justify-content: space-evenly;
	align-items: center;
	width: 100%;
	color: black !important;
	flex-wrap: wrap;
	margin-bottom: 55px;
}

.center-it-all {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	text-align: center;
	
	margin-top: 20px;
}

.center {
	text-align: center;
}

@import url("https://fonts.googleapis.com/css2?family=Righteous&display=swap");

.container {
	max-width: 1420px;
	width: max-content;
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-gap: 35px;
	margin: 0 auto;
	padding: 40px 0;
	justify-items: center;
	justify-content: space-between;
	justify-self: center;
}
.click {
	position: relative;
	color: whitesmoke;
	font-size: 30px;
	opacity: 1;
	font-weight: 600;
	text-shadow: black 0px 0px 10px;
	z-index: 1;
	user-select: none;
}
@media (max-width: 500px) {
	.click {
		font-size: 20px;
	}
}
.blur {
	position: absolute;
	background: #000000;
	filter: blur(15px);
	width: 100%;
	height: 100%;
	-webkit-filter: blur(15px);
	z-index: -1;
	display: grid;
	align-self: center;
	align-content: center;
}

body .container .card {
	position: relative;
	max-width: 600px;
	max-height: 750px;
	aspect-ratio: 600/750;
	margin: 0 auto;
	background: #000;
	border-radius: 15px;
	box-shadow: 0 15px 60px rgba(0, 0, 0, 0.5);
}

body .container .card .face {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

/* body .container .card .face.face1 {
	box-sizing: border-box;
	padding: 20px;
}

body .container .card .face.face2 {
	transition: 0.5s;
}

body .container .card:active .face.face2 {
	height: 60px;
}
body .container .card:active .click {
	display: none;
}
body .container .card:active .blur {
	display: none;
} */

body .container .card:nth-child(1) .face.face2 {
	background-image: url("../../img/007.png");
	background-size: 100%;
	max-width: 600px;
	max-height: 750px;
	aspect-ratio: 600/750;
	background-repeat: no-repeat;
}

body .container .card:nth-child(2) .face.face2 {
	background-image: url("../../img/nft5.png");
	background-size: 100%;
	max-width: 600px;
	max-height: 750px;
	aspect-ratio: 600/750;
	background-repeat: no-repeat;
}

@media (max-width: 1000px) {
	.container {
		grid-template-columns: repeat(1, 1fr);
	}
}
@media (max-width: 480px) {
}

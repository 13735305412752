.mint-wrapper{
    position: relative;
    z-index: 1000;
}

.mint-amount-wrapper {
    position: relative;
    z-index: 10;
    width: 285px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 9px;
    button {
        height: 70px;
        width: 65px;
        opacity: 1;
        background: linear-gradient(81.97deg,
            #1d4eff 0%,
            #ff44b4 49.73%,
            #ffd600 100%);
        box-shadow: 0px 12px 100px rgba(242, 68, 184, 0.4);
        border-radius: 12px;
        border: none;
        cursor: pointer;
        transition: 3ms;
    
        &:hover {
            opacity: 0.85;
            scale: 0.98;
        }
    
        &:active {
            opacity: 1;
            scale: 0.95;
        }
    
        transition: 0.3s;

        font-family: 'Poppins';
        color: white;
        font-size: 30px;
        font-weight: 600
    }

    input{
        width:133px;
        height: 69px;
        background-color: transparent;
        border:4px solid #ff44b4;
        border-radius: 12px;
        text-align: center;
        outline: none;
        font-family: 'Poppins';
        font-size: 20px;
        text-shadow: white 0px 0px 5px;

    }
 
}
.gallery {
	-webkit-column-count: 3;
	-moz-column-count: 3;
	column-count: 3;
	-webkit-column-width: 33%;
	-moz-column-width: 33%;
	column-width: 33%;
	padding: 30px 12px;
	gap: 15px;
	max-width: 768px;
}
@media (max-width: 480px) {
	.gallery {
		-webkit-column-count: 2;
		-moz-column-count: 2;
		column-count: 2;
	}
}
.gallery .pics {
	-webkit-transition: all 350ms ease;
	transition: all 350ms ease;
	cursor: pointer;
	margin-bottom: 12px;
	-ms-transition: all 350ms ease;
	-o-transition: all 350ms ease;
	-moz-transition: all 350ms ease;
}
.gallery .pics:hover {
	filter: opasity(0.8);
	-webkit-filter: opasity(0.8);
}

.model {
	width: 100%;
	height: 100vh;
	position: fixed;
	top: 0;
	left: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #000000be;
	visibility: hidden;
	opacity: 0;
	transform: scale(0);
	-webkit-transform: scale(0);
	-moz-transform: scale(0);
	-ms-transform: scale(0);
	-o-transform: scale(0);
	overflow: hidden;
	z-index: 999;
	transition: opacity 0.4s ease, visibility 0.4s ease,
		transform 0.5s ease-in-out;
	-webkit-transition: opacity 0.4s ease, visibility 0.4s ease,
		transform 0.5s ease-in-out;
	-moz-transition: opacity 0.4s ease, visibility 0.4s ease,
		transform 0.5s ease-in-out;
	-ms-transition: opacity 0.4s ease, visibility 0.4s ease,
		transform 0.5s ease-in-out;
	-o-transition: opacity 0.4s ease, visibility 0.4s ease,
		transform 0.5s ease-in-out;
}
.model.open {
	visibility: visible;
	opacity: 1;
	transform: scale(1);
	-webkit-transform: scale(1);
	-moz-transform: scale(1);
	-ms-transform: scale(1);
	-o-transform: scale(1);
}
.model img {
	width: auto;
	max-width: 100%;
	height: auto;
	max-height: 100%;
	display: block;
	line-height: 0;
	box-sizing: border-box;
	padding: 20px 0 20px;
	margin: 0 auto;
}

.model .img-nft {
	box-shadow: 0px 0px 25px #ffffff;
	max-width: 50%;
}

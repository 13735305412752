body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
		"Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
		"Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	overflow-x: hidden;
}

* {
	box-sizing: border-box;
}

#page-wrap {
	padding-bottom: 10px;
	padding-top: 10px;
}

@font-face {
	font-family: "SpecifyPersonalExpandedMedium-y5Pd";
	src: url("../public/fonts/SpecifyPersonalExpandedMedium-y5Pd.ttf");
}

@font-face {
	font-family: "SpecifyPersonalExpandedBlack-J1do";
	src: url("../public/fonts/SpecifyPersonalExpandedBlack-J1do.ttf");
}

@font-face {
	font-family: "SpecifyPersonalExpandedThin-lGRD";
	src: url("../public/fonts/SpecifyPersonalExpandedThin-lGRD.ttf");
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

@import url("https://fonts.googleapis.com/css?family=Montserrat:400,400i,700");

*,
::before,
::after {
	box-sizing: border-box;
	border-style: solid;
	border-width: 0;
}

html {
	/* background: #01012a; */
	color: #33def4;
}

html,
body {
	overscroll-behavior: none;
	font-family: Montserrat, sans-serif;
}

.box {
	padding: 32px;
}

h1 {
	font-size: 2.4rem;
	padding-bottom: 16px;
}

.loading {
	z-index: 9999;
	background: #01012ad8;
	color: rgb(248, 248, 248);
	font-family: "Poppins";
	font-size: 30px;
	text-align: center;
	position: fixed;
	width: 100%;
	min-height: calc(100vh - (100px));
	overflow: hidden;
	display: flex;
	justify-content: center;
	align-items: center;
	opacity: 1;
	visibility: visible;
	transition: opacity 0.8s 0.8s cubic-bezier(0.755, 0.05, 0.855, 0.06),
		visibility 0.8s 0.8s cubic-bezier(0.755, 0.05, 0.855, 0.06);
}

.loading.loaded {
	opacity: 0;
	visibility: hidden;
}

.loading .loading-container {
	position: relative;
	width: 40%;
	overflow: hidden;
}

.loading .loading-container .counter {
	padding: 0.8rem;
	transform: traslate(0, 0);
}

.loading .loading-container .counter.loaded {
	animation: counter-animation 0.8s cubic-bezier(0.755, 0.05, 0.855, 0.06) 0.8s 1 normal forwards running;
}

.loading .loading-container .line {
	position: absolute;
	background: rgb(248, 248, 248);
	left: 0;
	bottom: 0;
	width: 0;
	height: 2px;
}

.loading .loading-container .line.loaded {
	animation: line-animation 0.8s cubic-bezier(0.755, 0.05, 0.855, 0.06) 0s 1 normal forwards running;
}

@-moz-keyframes counter-animation {
	0% {
		transform: translate(0, 0);
	}

	100% {
		transform: translate(0, 10%);
	}
}

@-webkit-keyframes counter-animation {
	0% {
		transform: translate(0, 0);
	}

	100% {
		transform: translate(0, 10%);
	}
}

@-o-keyframes counter-animation {
	0% {
		transform: translate(0, 0);
	}

	100% {
		transform: translate(0, 10%);
	}
}

@keyframes counter-animation {
	0% {
		transform: translate(0, 0);
	}

	100% {
		transform: translate(0, 10%);
	}
}

@-moz-keyframes line-animation {
	0% {
		left: 0;
	}

	100% {
		left: 100%;
	}
}

@-webkit-keyframes line-animation {
	0% {
		left: 0;
	}

	100% {
		left: 100%;
	}
}

@-o-keyframes line-animation {
	0% {
		left: 0;
	}

	100% {
		left: 100%;
	}
}

@keyframes line-animation {
	0% {
		left: 0;
	}

	100% {
		left: 100%;
	}
}

.burger-btn {
	display: none;
}

@media (max-width: 768px) {
	.burger-btn {
		display: grid;
		width: 20px;
		height: 20px;
		margin-right: 20px;
		position: relative;
		cursor: pointer;
	}
}

.burger-btn:before {
	content: "";
	position: absolute;
	top: 0;
	width: 20px;
	background-color: black;
	height: 2px;
}

.burger-btn:after {
	content: "";
	position: absolute;
	bottom: 0;
	width: 20px;
	background-color: black;
	height: 2px;
}

.burger-btn span {
	position: absolute;
	top: 9px;
	width: 20px;
	background-color: black;
	height: 2px;
}

.menu {
	position: fixed;
	width: 100vw;
	height: 100vh;
	top: 100px;
	right: 0;
	transform: translateX(-130%);
}

.menu-active {
	transform: translateX(0);
}

@media (max-width: 1300px) {
	.card {
		flex: initial;
		position: relative;
		background-size: 100%;
		width: 455px;
		height: 570px;
		aspect-ratio: 650/813;
		display: grid;
		align-items: center;
		transition: all 0.5s ease;
	}
}

@media (max-width: 889px) {
	.card {
		flex: initial;
		position: relative;
		background-size: 100%;
		width: 90vw;
		height: 100%;
		aspect-ratio: 650/813;
		display: grid;
		align-items: center;
		transition: all 0.5s ease;
		grid-column-gap: 20px;
	}
}

.nft_hidden {
	display: none;
}

.nft_show {
	display: block;
}

@import url("https://cdn.jsdelivr.net/npm/lightgallery@2.4.0/css/lightgallery.css");
@import url("https://cdn.jsdelivr.net/npm/lightgallery@2.4.0/css/lg-zoom.css");
@import url("https://cdn.jsdelivr.net/npm/lightgallery@2.4.0/css/lg-video.css");

.gallery-item {
	margin: 5px;
}

.size-nft {
	width: 100px;
	height: 100px;
}